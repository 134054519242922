/* JJK Extensions */
import dashboard from "@/extensions/dashboard";
import budget from "@/extensions/budget";
import projects from "@/extensions/projects";
import { JJKExtension } from "@/types";

export const jjkExtensions: JJKExtensions = {
  dashboard,
  budget,
  projects
};

export type JJKExtensions = {
  [extension: string]: JJKExtension;
};

export type State = {
  dashboard: typeof dashboard.store.state;
  budget: typeof budget.store.state;
  projects: typeof projects.store.state;
};

// export type State = {
//   [K in keyof typeof jjkExtensions]: typeof jjkExtensions.K.store.state;
// };
