import { JJKExtension } from "@/types";
import routes from "./router";
import budgetModule from "./store";
import { calculatorOutline } from "ionicons/icons";
import { useAccessor as useAccessorFromStore } from "typed-vuex";
import { useStore } from "@/services/store";

const name = "Budget";
const moduleName = name.toLowerCase();

const extensionConfig: JJKExtension = {
  name,
  description: "Monthly budget",
  version: "0.0.1",
  author: "Jacob Janisz",
  authorEmail: "jacob@jojko.tech",
  enabled: true,
  body: () => import("./views/index.vue"),
  actions: () => import("./jjk-components/actions.vue"),
  menu: () => import("./jjk-components/menu.vue"),
  menuEntry: {
    name,
    path: `/${moduleName}`,
    icon: calculatorOutline
  },
  store: budgetModule,
  router: routes,

  settings: {
    a: "s"
  }
};

export const useAccessor = () => {
  const store = useStore();

  if (!store.state.budget)
    store.registerModule(moduleName, budgetModule, {
      preserveState: false
    });

  const accessor = useAccessorFromStore(store, budgetModule, moduleName);

  return accessor;
};

export default extensionConfig;
