import { RouteRecordRaw } from "vue-router";

import { router as createRouter } from "@/services/router";
import { JJKExtensionList } from "@/types";

export const useExtensions = (extensions: JJKExtensionList) => {
  const routes: RouteRecordRaw[] = [];

  Object.keys(extensions).forEach(key => {
    const extRouter = extensions[key].router;

    if (extRouter) {
      extRouter.forEach((route: RouteRecordRaw) => {
        routes.push(route);
      });
    }
  });

  const { router, RouterSymbol } = createRouter(routes);

  return { router, RouterSymbol };
};
