import { RGBColor } from "@/types/utils";
import { useAccessor, getterTree, mutationTree, actionTree } from "typed-vuex";
import { Budget } from "./types";

export const namespaced = true;

const budgets = [
  {
    id: 1,
    from: new Date(2021, 2, 1),
    to: new Date(2021, 2, 28),
    currentState: 0,
    accounts: [
      {
        name: "Utilities",
        bank: {
          name: "Barclays",
          logoURL: "",
          color: new RGBColor(0, 0, 255)
        },
        balance: 0
      }
    ]
  }
] as Budget[];

export const state = () => ({
  counter: 1,
  budgets
});

export const mutations = mutationTree(state, {
  add(state, payload: number) {
    // here logic
  }
});

export const getters = getterTree(state, {
  counter: state => state.counter,
  budgets: state => state.budgets
});

export const actions = actionTree(
  { state, getters, mutations },
  {
    async add({ commit }) {
      commit("add", 2);
    }
  }
);

export default {
  state,
  mutations,
  actions,
  getters
};
