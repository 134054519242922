import { createApp } from "vue";
import App from "./App.vue";
import { createStore } from "@/services/store";

import { IonicVue } from "@ionic/vue";

import { useExtensions } from "@/services/utils";

/* Global components */

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */

import "./theme/variables.css";
import { jjkExtensions } from "./services/extensions";

const { store, StoreSymbol } = createStore();

const { router, RouterSymbol } = useExtensions(jjkExtensions);

const app = createApp(App)
  .provide(RouterSymbol, router)
  .provide(StoreSymbol, store)
  .use(IonicVue)
  .use(store)
  .use(router);

//store.commit('increment');

router.isReady().then(() => {
  app.mount("#app");
});
