import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from "vuex";
import { State as RootState } from "@/services/extensions";

export enum MutationTypes {
  ADD = "ADD"
}

export enum ActionTypes {
  ADD = "ADD"
}

export type State = {
  counter: number;
};

export type Getters = {
  counter(state: State): number;
};

export interface Mutations<S = State> {
  [MutationTypes.ADD](state: S, payload: number): void;
}

type AugumentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]> & Omit<ActionContext<State, RootState>, "commit">;
};

export interface Actions {
  [ActionTypes.ADD]({ commit }: AugumentedActionContext, payload: number): void;
}

interface DashboardStore extends Module<State, RootState> {
  state: State;
  mutations: MutationTree<State> & Mutations;
  actions: ActionTree<State, RootState> & Actions;
  getters: GetterTree<State, RootState> & Getters;
}

export default DashboardStore;
