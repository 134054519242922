import { createLogger, createStore as createVueXStore } from "vuex";
import { State as RootState } from "@/services/extensions";
import { Store } from "vuex";
import { inject } from "vue";
import { useAccessor } from "typed-vuex";

const StoreSymbol = Symbol();

const storePattern = {
  state: () => ({}),
  mutations: {},
  actions: {},
  getters: {},
  plugins: process.env.NODE_ENV === "production" ? [] : [createLogger()]
};

export const createStore = () => {
  const store = createVueXStore(storePattern);

  return { store, StoreSymbol };
};

export function useStore() {
  const store = inject(StoreSymbol) as Store<RootState>;
  if (!store) {
    throw new Error("App error: No store provided!");
  }
  return store;
}

const store = createStore().store;

export const accessor = useAccessor(store, storePattern);
