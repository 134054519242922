<template>
  <ion-menu side="start" menu-id="main" content-id="main">
    <ion-header>
      <ion-toolbar>
        <ion-row>
          <img src="/assets/logo-raw-white.svg" />
          <ion-title>jjk-cloud</ion-title>
        </ion-row>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-menu-toggle
          auto-hide="false"
          v-for="(menuEntry, i) of menuEntries"
          :key="i"
        >
          <ion-item
            @click="selectedIndex = i"
            button
            router-direction="root"
            :router-link="menuEntry.path"
            lines="none"
            detail="false"
            class="hydrated"
            :class="{ selected: selectedIndex === i }"
          >
            <ion-icon :icon="menuEntry.icon" />
            <ion-label>{{ menuEntry.name }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  menuController,
  IonIcon,
  IonLabel,
  IonRow,
  IonMenuToggle
} from "@ionic/vue";
import {
  hammerOutline,
  schoolOutline,
  constructOutline,
  calculatorOutline
} from "ionicons/icons";
import { useStore } from "@/services/store";
import { jjkExtensions } from "@/services/extensions";
import { JJKMenuEntry } from "@/types";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Menu",
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonRow,
    IonMenuToggle
  },

  setup() {
    const selectedIndex = ref(0);
    const menuEntries: JJKMenuEntry[] = [];

    const route = useRoute();
    const path = route.path;

    Object.keys(jjkExtensions).forEach(key => {
      const menuEntry = jjkExtensions[key].menuEntry;
      if (menuEntry) menuEntries.push(menuEntry);
    });

    if (path !== undefined) {
      selectedIndex.value = menuEntries.findIndex(
        page => page.path.toLowerCase() === path.toLowerCase()
      );
    }

    //TODO: implement extensions' sub-menu

    menuController.enable(true, "main");
    menuController.open("main");
    return {
      hammerOutline,
      schoolOutline,
      constructOutline,
      calculatorOutline,
      menuEntries,
      selectedIndex
    };
  }
});
</script>

<style lang="scss" scoped>
@use '../theme/globals';

ion-menu {
  width: 15em;
  ion-header {
    ion-toolbar {
      --background: rgba(var(--ion-color-primary-rgb), 0.14);
      --color: var(--ion-color-primary);
      img {
        height: 2em;
        padding: 0.9em;
        padding-left: 1em;
        box-sizing: content-box;
        filter: invert(40%) sepia(67%) saturate(1090%) hue-rotate(193deg)
          brightness(101%) contrast(103%);
      }
      ion-title {
        padding-left: 0.5em;
      }
    }
  }
}

ion-list {
  height: 100%;
}

ion-item {
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  ion-icon {
    margin-right: 0.7em;
  }
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  justify-content: space-between;
  display: flex;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list {
  height: inherit;
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
ion-icon {
  margin-right: 0.5em;
}

// Mobile
$mobile-breakpoint: (globals.$breakpoint - 1px);
@media screen and (max-width: $mobile-breakpoint) {
  ion-menu {
    display: none;
  }
}
</style>
