
import { defineComponent, ref } from "vue";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  menuController,
  IonIcon,
  IonLabel,
  IonRow,
  IonMenuToggle
} from "@ionic/vue";
import {
  hammerOutline,
  schoolOutline,
  constructOutline,
  calculatorOutline
} from "ionicons/icons";
import { useStore } from "@/services/store";
import { jjkExtensions } from "@/services/extensions";
import { JJKMenuEntry } from "@/types";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Menu",
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonLabel,
    IonRow,
    IonMenuToggle
  },

  setup() {
    const selectedIndex = ref(0);
    const menuEntries: JJKMenuEntry[] = [];

    const route = useRoute();
    const path = route.path;

    Object.keys(jjkExtensions).forEach(key => {
      const menuEntry = jjkExtensions[key].menuEntry;
      if (menuEntry) menuEntries.push(menuEntry);
    });

    if (path !== undefined) {
      selectedIndex.value = menuEntries.findIndex(
        page => page.path.toLowerCase() === path.toLowerCase()
      );
    }

    //TODO: implement extensions' sub-menu

    menuController.enable(true, "main");
    menuController.open("main");
    return {
      hammerOutline,
      schoolOutline,
      constructOutline,
      calculatorOutline,
      menuEntries,
      selectedIndex
    };
  }
});
