export class RGBColor {
  constructor(public r: number, public g: number, public b: number) {}

  get() {
    return `${this.r}, ${this.g}, ${this.b}`;
  }

  toCSSColor() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }

  toHex() {
    let r = this.r.toString(16);
    let g = this.g.toString(16);
    let b = this.b.toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;

    return "#" + r + g + b;
  }
}
