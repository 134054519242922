import { JJKExtension } from "@/types";
import { homeOutline } from "ionicons/icons";
import routes from "./router";
import { dashboardModule } from "./store";

const extensionConfig: JJKExtension = {
  name: "Dashboard",
  description: "Start page of the app",
  version: "0.0.1",
  author: "Jacob Janisz",
  authorEmail: "jacob@jojko.tech",
  enabled: true,
  body: () => import("./body.vue"),
  actions: () => import("./actions.vue"),
  menu: () => import("./menu.vue"),
  store: dashboardModule,

  menuEntry: {
    name: "Dashboard",
    path: "/dashboard",
    icon: homeOutline
  },
  router: routes,

  settings: {
    a: "s"
  }
};

export default extensionConfig;
