import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  {
    path: "budget",
    component: () => import("./views/index.vue"),
    children: [
      {
        path: "",
        component: () => import("./views/Dashboard.vue")
      },
      {
        path: "budgets",
        component: () => import("./views/Budgets.vue"),
        children: [
          {
            path: "new",
            component: () => import("./views/NewBudget.vue")
          },
          {
            path: `view/:id`,
            props: true,
            component: () => import("./views/ViewBudget.vue")
          }
        ]
      }
    ]
  }
];

export default routes;
